import React from "react";
import PropTypes from "prop-types";
import useTranslation from "../hooks/useTranslation";

import "./Container.scss";

function CourseIntroduction(props) {
    const {t, language, translations} = useTranslation();

    const getKeyVariants = (lang, mutationsKey) => {
        let variants = [];
        Object.keys(translations).forEach(key => {
            key = key.split(".")[0];
            if (key && key.match(`${mutationsKey}_`) && variants.indexOf(key) === -1) {
                variants.push(key);
            }
        });
        return variants;
    }

    const keyVariants = getKeyVariants(language, props.mutationKey);

    const filteredKeys = (lang, filter) => {
        let key, keys = {};
        for (key in translations) {
            if (translations.hasOwnProperty(key) && key.match(filter))
                keys[key] = translations[key];
        }
        return keys;
    }

    const renderRows = (headerKey) => {
        const langKeys = filteredKeys(language, `${headerKey}..*`);
        const result = Object.values(langKeys).map((val) => (
            <p dangerouslySetInnerHTML={{__html: val}}/>
        ));
        return React.Children.toArray(result);
    }

    function renderContents() {
        if (language && translations) {
            const result = keyVariants.map((key) => {
                return (
                    <div>
                        <h2>{t(key)}</h2>
                        {renderRows(key)}
                    </div>
                );
            });
            return React.Children.toArray(result);
        }
    }

    return <div className="CourseIntroduction">{renderContents()}</div>;
}

CourseIntroduction.propTypes = {
    mutationKey: PropTypes.string,
};

export default CourseIntroduction;
