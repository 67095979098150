import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Config} from "../../config/Config";
import Button from "./Button";
import {useAppData} from "../../context/AppDataContext";
import useTranslation from "../../hooks/useTranslation";
import {useUserData} from "../../context/UserContext";
import {axiosInstance} from "../../axios";
import ErrorMessage from "../ErrorMessage";

import "./Course.scss";

const Course = ({hideCapacity = false, hideButton, course, setDisabled}) => {
    const {data} = useAppData();
    const {t} = useTranslation();
    const {courseRegistration, setCourseRegistration, user} = useUserData();
    const [error, setError] = useState();

    const _renderCourse = () => {
        let {
            code,
            courseDateList,
            note,
            startDate,
            endDate,
            place,
            floorAndRoom,
            level,
            capacity,
            _id,
            variant,
            total
        } = course;

        const prefLvlCfg = data.period.levelPointsConfig.find(lvlGroup => user.typeList.some(usrType => lvlGroup.acceptableTypes.indexOf(usrType) !== -1));
        if (prefLvlCfg) {
            const prefLvl = prefLvlCfg.config.find(lvl => level.indexOf(lvl._id) !== -1);
            if (prefLvl) {
                level = prefLvl.level;
            }
        } else {
            const allLevels = data.period.levelPointsConfig.flatMap(level => level.config);
            const lvl = allLevels.find(lvl => level.indexOf(lvl._id) !== -1);
            if (lvl) {
                level = lvl.level;
            }
        }

        const showButton = (courseId) => {
            if (courseRegistration?.course) {
                return courseRegistration.course === courseId;
            }
            return true;
        }

        const courseStart = new Date(startDate).toLocaleDateString();
        const courseEnd = new Date(endDate).toLocaleDateString();
        let courseDays = [];
        let time;
        courseDateList.forEach((courseDate, i) => {
                courseDays.push(t(Config.days[courseDate.day]));
                time = `${courseDate.start} - ${courseDate.end}`;
            }
        );
        courseDays = courseDays.join(" + ");

        const buttonText = courseRegistration?.course === _id ? "removeCourseRegistration" : "signUpAsCandidate";
        let courseClassName = `Course-wrapper-course-container`
        variant && (courseClassName += ` ${variant}`);
        return (
            <div className="Course-wrapper-course">
                <div className={courseClassName}>
                    <div className="Course-wrapper-course-container-header">
                        <h3>{t("czechCourse")}</h3>
                        <label>{code}</label>
                    </div>
                    <div className="info">
                        <p>{t("level")}</p>
                        <p>{level}</p>
                    </div>
                    {
                        variant && <div className="info">
                            <p>{t("variant")}</p>
                            <p>{t(variant)}</p>
                        </div>
                    }
                    <div className="info">
                        <p>{t("courseDates")}</p>
                        <p>{courseDays}</p>
                        <p>{time}</p>
                    </div>
                    <div className="info">
                        <p>{t("courseStart")}</p>
                        <p>{courseStart}</p>
                    </div>
                    <div className={"info"}>
                        <p>{t("courseEnd")}</p>
                        <p>{courseEnd}</p>
                    </div>
                    <div className={"info"}>
                        <p>{t("coursePlace")}</p>
                        <p>{place}</p>
                    </div>
                    {
                        floorAndRoom && <div className={"info"}>
                            <p>{t("floorAndRoom")}</p>
                            <p>{floorAndRoom}</p>
                        </div>
                    }
                    {
                        !hideCapacity
                        && <div className={"info"}>
                            <p>{t("applicants")}/{t("capacity")}</p>
                            <p>{total}/{capacity}</p>
                        </div>
                    }
                    {note && <div className={"info"}>
                        <p>{t("note")}</p>
                        <p>{note}</p>
                    </div>}
                    <div className={"Course-wrapper-course-container-footer"}>
                        {
                            !hideButton && showButton(_id) && <Button onClick={() => handleCourseRegistration(_id)}
                                                                      label={t(buttonText)} type="button"/>
                        }
                    </div>
                    {error && <ErrorMessage error={error}/>}
                </div>
            </div>
        );
    }

    const handleCourseRegistration = async (courseId) => {
        setDisabled(true);
        try {
            if (courseRegistration) {
                await axiosInstance.delete('/api/courseRegistration/cancel');
                setCourseRegistration(null);
            } else {
                alert(t('assignedInCourse'));
                const {data} = await axiosInstance.post('/api/courseRegistration/enroll', {course: courseId});
                setCourseRegistration(data);
            }
        } catch (e) {
            setError(e);
        } finally {
            setDisabled(false);
        }
    }

    return (
        <div className="Course-wrapper">
            {_renderCourse()}
        </div>
    );

}
Course.propTypes = {
    hideButton: PropTypes.bool,
    hideCapacity: PropTypes.bool,
    course: PropTypes.shape({
        capacity: PropTypes.number,
        total: PropTypes.number,
        code: PropTypes.string,
        _id: PropTypes.string,
        courseDateList: PropTypes.array,
        note: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        place: PropTypes.string,
        floorAndRoom: PropTypes.string,
        level: PropTypes.arrayOf(PropTypes.string),
    }),
    registrationStart: PropTypes.string,
    registrationEnd: PropTypes.string,
    disabled: PropTypes.bool
};

export default Course;
