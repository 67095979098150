import React, {useEffect, useState} from "react";
import Course from "../../bricks/forms/Course";
import AppInfo from "../../bricks/AppInfo";
import {useUserData} from "../../context/UserContext";
import useTranslation from "../../hooks/useTranslation";
import CourseOverviewInfo from "./CourseOverview-Info";
import CourseOverviewPayment from "./CourseOverview-Payment";
import CourseOverviewOtherTests from "./CourseOverview-OtherTests";
import {axiosInstance} from "../../axios";

import "./CourseOverview.scss";

const CourseOverview = () => {
    const {t} = useTranslation();
    const [switchValue, setSwitchValue] = useState('yourCourse');
    const {courseRegistration} = useUserData();
    const [course, setCourse] = useState();
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const {data} = await axiosInstance.get(`/api/course/${courseRegistration.course}`);
                setCourse(data);
            } catch (error) {
                setError(error)
            } finally {
                setIsLoading(false);
            }
        };
        courseRegistration && init();
    }, [courseRegistration]);

    const _renderSwitch = () => {
        const switchItems = [
            {
                label: "yourCourse",
                value: 'yourCourse',
                disabled: false
            },
            {
                label: "motivationalDeposit",
                value: 'deposit',
                disabled: courseRegistration.isAlternate !== false
            },
            {
                label: "tests",
                value: 'tests',
                disabled: courseRegistration.isAlternate !== false
            }
        ];

        return (
            <div className="switch-container">
                {
                    React.Children.toArray(switchItems
                        .filter(item => !item.disabled)
                        .map(item => (
                            <div className={`switch-item ${switchValue === item.value ? 'active' : ''}`}
                                 onClick={() => _handleSwitchChange(item.value)}>
                                <label>{t(item.label)}</label>
                            </div>))
                    )
                }
            </div>
        );
    }

    const _handleSwitchChange = (value) => {
        setSwitchValue(value);
    }


    const renderTab = () => {
        switch (switchValue) {
            case 'yourCourse':
                return <CourseOverviewInfo/>;
            case 'deposit':
                return <CourseOverviewPayment/>;
            default:
                return <CourseOverviewOtherTests course={course}/>;
        }
    }

    return (
        <div className={"CourseOverview"}>
            <AppInfo step={5}/>
            {/*{!(error && error.errorCode === 'cantViewCourse') && <ErrorMessage error={error}/>}*/}
            {(courseRegistration.isAlternate === false) && _renderSwitch()}
            <div>
                <div className="your-course-container">
                    {
                        course
                        && (courseRegistration.isAlternate === false)
                        && <Course hideButton hideCapacity course={course}/>
                    }
                    {renderTab()}
                </div>
            </div>
        </div>
    );

}

export default CourseOverview;
